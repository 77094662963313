* {
  box-sizing: border-box;
}
.projects__container {
  min-height: 100vh;
  width: 100vw;
  padding: 30px;
  padding-top: 60px;
}

.main__card {
  width: 80%;
  padding: 20px;
  border: 2px solid blue;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-content: space-around;
}
.inner__Card {
  width: 550px;

  background-color: rgb(166, 225, 250);
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.inner__Card .card__img {
  width: 400px;
  align-self: center;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.inner__Card .buttons {
  align-self: center;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 60%;
  display: flex;
  justify-content: center;
}
.inner__Card .buttons Button {
  margin-right: 20px;
}
.inner__Card .buttons img {
  width: 25px;
  margin-left: 6px;
}
.inner__Card .buttons a {
  text-decoration: none;
}
.title {
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 500px) {
  .inner__Card {
    width: 90vw;
    height: auto;
    background-color: rgb(166, 225, 250);
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .inner__Card .card__img {
    width: 100%;
    align-self: center;
    margin-bottom: 10px;
  }
  .inner__Card .buttons {
    align-self: center;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .inner__Card h3 {
    font-size: medium;
  }
  .inner__Card p {
    font-size: small;
  }
  .title {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
  .inner__Card .buttons Button {
    font-size: 12px;
    margin-right: 10px;
  }
  .inner__Card .buttons img {
    width: 20px;
    margin-left: 6px;
  }
}
