.nav {
  display: flex;
  width: 100vw;
  height: 60px;
  position: fixed;
  justify-content: space-between;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  align-items: center;
  z-index: 100;
}
.nav__black {
  background-color: rgb(0, 28, 85);
  color: white;
}
.nav__title {
  padding: 10px;
}
.nav__links {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.nav__links h3 {
  padding: 10px;
  cursor: pointer;
}
@media (max-width: 500px) {
  .nav__title {
    padding: 10px;
    font-size: 1em;
  }
  .nav__links h3 {
    padding: 10px;
    font-size: 0.7em;
  }
}
