.skills__root {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skills__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.outer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.logo {
  width: 100px;
  height: 100px;
}

@media (max-width: 500px) {
  .outer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    margin: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .title {
    font-size: 30px;
  }
  .skills__root h5 {
    font-size: 12px;
  }
}
