.home__root {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
}
.home__typography {
  text-align: center;
  color: rgb(0, 28, 85);
  font-family: "Google Sans Bold";
  padding: 5px;
}
.home__typography h1 {
  font-size: 80px;
}
.arrow {
  cursor: pointer;
  width: 40px;
  height: 50px;
}
.codingSvg svg {
  width: 500px;
}
.footer {
  position: fixed;
  bottom: 10px;
}
@media (max-width: 500px) {
  .home__root {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .home__typography h1 {
    font-size: 40px;
  }
  .home__typography h4 {
    font-size: 12px;
  }
  .codingSvg svg {
    margin-bottom: 10px;
    width: 250px;
    height: fit-content;
  }
}
