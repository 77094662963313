.about__root {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about__card {
  width: 70%;
  height: 70%;
  background-color: rgb(0, 28, 85);
  padding: 10px;
  text-align: justify;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about__card.MuiPaper-root {
  background-color: #2961a5;
}
.card__content {
  width: 50%;
  color: #fff;
}
.about__root .heading {
  font-family: "Google Sans Bold";
  font-size: 40px;
}
.card__content .main {
  font-family: "Google Sans Regular";
  font-size: 18px;
}
.profile__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile {
  height: 300px;
  border: 2px solid rgb(237, 249, 254);
  margin: 20px;
  border-radius: 50%;
}

@media (max-width: 500px) {
  .about__card {
    width: 90%;
    height: auto;
    background-color: rgb(0, 28, 85);
    padding: 10px;
    text-align: justify;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
  }
  .card__content {
    width: 90%;
    color: #fff;
  }
  .profile {
    height: 100px;
    border: 2px solid rgb(237, 249, 254);
    margin: 20px;
    border-radius: 50%;
  }
  .about__root .heading {
    font-family: "Google Sans Bold";
    font-size: 30px;
  }
  .card__content .main {
    font-family: "Google Sans Regular";
    font-size: 14px;
  }
}
