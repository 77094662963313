@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./Assets/fonts/Agustina.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./Assets/fonts/Montserrat-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold";
  src: local("Google Sans Bold"),
    url("./Assets/fonts/GoogleSans-Bold.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold Italic";
  src: local("Google Sans Bold Italic"),
    url("./Assets/fonts/GoogleSans-BoldItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Italic";
  src: local("Google Sans Italic"),
    url("./Assets/fonts/GoogleSans-Italic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium";
  src: local("Google Sans Medium"),
    url("./Assets/fonts/GoogleSans-Medium.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium Italic";
  src: local("Google Sans Medium Italic"),
    url("./Assets/fonts/GoogleSans-MediumItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Regular";
  src: local("Google Sans Regular"),
    url("./Assets/fonts/GoogleSans-Regular.ttf") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: rgb(237, 249, 254);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
