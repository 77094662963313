.social-media-div {
  font-size: 2em;
  margin-top: 20px;
}

.icon-button i {
  color: white;
}
.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
}

/* .facebook i {
  background-color: #3b5998;
}

.linkedin i {
  background-color: #0e76a8;
}

.github i {
  background-color: #333;
}

.gitlab i {
  background-color: #fca326;
}

.google i {
  background-color: #ea4335;
}

.twitter i {
  background-color: #1da1f2;
}

.instagram i {
  background-color: #E4405F;
} */

/*Hover Transition*/
/* .twitter i:hover, .google i:hover, .gitlab i:hover,
.github i:hover ,.linkedin i:hover ,.facebook i:hover, .instagram i:hover{
  background-color: black;
  transition: 0.3s ease-in;
} */

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
